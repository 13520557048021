import { createApp } from 'vue'
import App from './App.vue'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "../src/assets/Ali/iconfont.css"

import router from './router/index.ts'

createApp(App).use(router).use(ElementPlus).mount('#app')
