import { createRouter, createWebHistory } from 'vue-router'
 
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../view/Home/home.vue'),
    redirect: '/analysis',
    children: [
      {
        path: '/analysis',
        name: 'analysis',
        component: () => import('../view/Home/analysis/analysis.vue')
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import('../view/Home/introduce/introduce.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../view/Home/contact/contact.vue')
      },
    ]
  }
]
 
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
 
export default router
